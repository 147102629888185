import * as React from "react"

import ToolScreen from '../../../../components/toolScreen'


const Tool = ({ data }) => (
  <ToolScreen
    name="Framer"
    img_name="framer_logo.png"
    link="https://www.framer.com/"
    description="Framer is the design software of choice for anyone ready to write code in React to make advanced app prototypes."
    twitterHandleTool="@framer"
  />
)

export default Tool;


